import React from 'react'
import Layout from '../../components/layout'
import PolicyDetails from '../../components/terms/professional-services-agreement-previous'
import TermsGrid from '../../components/terms/terms-grid'
import SEO from '../../components/seo'

const ProfessionalServicesAgreement = () => {
  return (
    <Layout>
      <SEO
        title='Professional Service Agreement'
        pageType='home'
        flowType='none'
      />
      {typeof window !== 'undefined' && (
        <TermsGrid pathname={window.location.pathname} />
      )}
      <PolicyDetails />
    </Layout>
  )
}

export default ProfessionalServicesAgreement
